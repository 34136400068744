import { render, staticRenderFns } from "./VerifyDocumentContainer.vue?vue&type=template&id=34b2771a&"
import script from "./VerifyDocumentContainer.vue?vue&type=script&lang=js&"
export * from "./VerifyDocumentContainer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports