<template>
  <label class="block text-sm font-medium leading-5 text-gray-700">
    {{ label }}
    <select
      v-bind="$attrs"
      v-on="computedListeners"
      :class="selectClass"
      class="mt-1 block form-select w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
    >
      <slot></slot>
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="optionValue ? option[optionValue] : option"
      >
        {{ optionName ? option[optionName] : option }}
      </option>
    </select>
  </label>
</template>

<script>
export default {
  name: 'SelectField',
  props: {
    label: {
      type: String,
      required: true
    },
    selectClass: {
      type: String,
      required: false
    },
    optionName: {
      type: String || Number,
      required: false
    },
    optionValue: {
      type: String || Number,
      required: false
    },
    options: {
      type: Array,
      required: true
    }
  },
  computed: {
    computedListeners() {
      return Object.assign({}, this.$listeners, {
        input: e => this.$emit('input', e.target.value)
      })
    }
  }
}
</script>
