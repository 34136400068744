<template>
  <div class="shadow overflow-hidden sm:rounded-md">
    <div class="px-4 py-5 bg-white sm:p-6">
      <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6">
          <TextField
            :labelClass="correctLabelClass"
            :label="correctLabel"
            v-model="selectedNames"
          ></TextField>
        </div>
        <div class="col-span-6 lg:col-span-3">
          <SelectField
            :label="this.$t('region')"
            v-model="selectedRegion"
            :options="voivodeships"
            :optionValue="'id'"
            selectClass="lowercase"
            :optionName="'name'"
          >
            <option value=""></option>
          </SelectField>
        </div>
        <div class="col-span-6 lg:col-span-3">
          <TextField
            :label="this.$t('decision_number')"
            v-model="selectedDecisionNum"
          ></TextField>
        </div>
        <div class="col-span-6">
          <SelectField
            :label="
              this.$t('realm') + ' ' + '(' + this.$t('type_of_permission') + ')'
            "
            v-model="selectedDiscipline"
            :options="disciplines"
            :optionValue="'ids'"
            :optionName="'name'"
          >
            <option value=""></option>
          </SelectField>
        </div>
        <div class="col-span-6">
          <SelectField
            :label="this.$t('category')"
            v-model="selectedCategory"
            :options="categories"
            :optionValue="'id'"
            :optionName="'name'"
          >
            <option value=""></option>
          </SelectField>
        </div>
        <transition name="slide-fade">
          <div v-if="selectedCategory" class="col-span-6">
            {{ this.$t('range') }}
            <label class="flex items-center ml-2 mt-4">
              <input
                class="form-radio"
                type="radio"
                value=""
                v-model="selectedExtent"
              />
              <span class="ml-2">{{ this.$t('all_extents') }}</span>
            </label>
            <label
              v-for="item in extents"
              :key="item.id"
              class="flex items-center ml-2 mt-4"
            >
              <input
                class="form-radio"
                type="radio"
                :value="item.ids"
                v-model="selectedExtent"
              />
              <span class="ml-2">{{ item.name }}</span>
            </label>
          </div>
        </transition>
        <transition name="slide-fade">
          <div v-if="selectedExtent && extents.length > 0" class="col-span-6">
            <p class="mb-3">
              <span class="capitalize">{{ this.$t('category') }}</span>
              "{{ categoryName }}" {{ this.$t('in_terms_of') }} "{{
                extentName
              }}" {{ this.$t('range_info') }}
            </p>
            <div class="border-b border-t">
              <ul class="ml-5 list-disc py-3">
                <li v-for="(option, index) in findSpecialities" :key="index">
                  {{ option }}
                </li>
              </ul>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="px-4 py-3 bg-white text-right sm:px-6">
      <transition name="slide-fade">
        <button
          v-if="queryNotEmpty"
          @click="startSearching"
          href="#table"
          class="btn-search inline-block"
          v-smooth-scroll
        >
          {{ this.$t('search') }}
        </button>
      </transition>
    </div>
  </div>
</template>

<script>
import TextField from './TextField'
import SelectField from './SelectField'
import { selected } from '../mixins/selected.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DefaultSearch',
  mixins: [selected],
  components: { SelectField, TextField },
  computed: {
    ...mapGetters({
      disciplines: 'disciplines/mapDisciplines',
      voivodeships: 'voivodeships/mapVoivodeships',
      categories: 'specialityCategories/mapCategories',
      categoryName: 'specialityCategories/categoryName',
      extentName: 'specialityCategories/extentName',
      findSpecialities: 'specialityCategories/findSpecialities',
      queryParams: 'selectedSet/getQueryParams',
      mapNames: 'selectedSet/mapNames',
      testNames: 'selectedSet/testNames',
      queryNotEmpty: 'selectedSet/queryNotEmpty',
      queryIsEmpty: 'selectedSet/queryIsEmpty',
      extents: 'specialityCategories/mapExtents'
    }),
    correctLabel() {
      return this.testNames || !this.selectedNames
        ? this.$t('names')
        : this.$t('set_correct_names')
    },
    correctLabelClass() {
      return this.testNames || !this.selectedNames ? '' : 'text-red-700'
    }
  },
  methods: {
    ...mapActions({
      search: 'professionalCards/getProfessionalCards'
    }),
    startSearching() {
      this.$store.commit('base/SET_HIDDEN')
      this.search(this.queryParams)
    }
  },
  watch: {
    selectedCategory: {
      handler() {
        this.selectedExtent = null
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(50px);
  opacity: 0;
}
</style>
