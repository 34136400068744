<template>
  <div
    v-if="totalRecords"
    class="bg-white px-4 py-3 flex items-center justify-between
              border-t border-gray-200 sm:px-6 flex-row-reverse sm:flex-row"
  >
    <div>
      <p class="text-sm leading-5 text-gray-700">
        {{ this.$t('people_found') }}:
        <span class="font-medium">{{ totalRecords }}</span>
      </p>
      <p class="text-sm leading-5 text-gray-700">
        {{ this.$t('page') }}: <span>{{ this.page }}</span> {{ this.$t('of') }}
        {{ allPages }}
      </p>
    </div>
    <div>
      <button
        @click="previousPage"
        :disabled="page === 1"
        :class="searchButton"
      >
        {{ this.$t('previous_page') }}
      </button>
      <button
        :class="searchButton"
        @click="nextPage"
        :disabled="page === allPages"
      >
        {{ this.$t('next_page') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'AppraiserPagination',
  data: function() {
    return {
      searchButton:
        'ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 ' +
        'font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue ' +
        'focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150 disabled:hidden'
    }
  },
  computed: {
    ...mapState({
      totalRecords: state => state.appraiserCards.totalRecords,
      page: state => state.selectedSet.pagination.page,
      limit: state => state.selectedSet.pagination.limit
    }),
    ...mapGetters({
      queryParams: 'selectedSet/getQueryParams'
    }),
    allPages() {
      return this.totalRecords && this.page
        ? Math.ceil(this.totalRecords / this.limit)
        : false
    }
  },
  methods: {
    ...mapActions({
      search: 'appraiserCards/getAppraiserCards'
    }),
    nextPage() {
      this.$store.commit('selectedSet/NEXT_PAGE')
      const params = this.queryParams
      this.search(params)
    },
    previousPage() {
      this.$store.commit('selectedSet/PREVIOUS_PAGE')
      const params = this.queryParams
      this.search(params)
    }
  }
}
</script>
