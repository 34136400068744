<template>
  <div id="table" class="flex flex-col">
    <div class="bg-indigo-600">
      <div class="max-w-screen-xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <span class="flex py-2">
              <img
                class="h-6 w-6 hidden lg:block"
                :alt="$t('header_message2')"
                src="../assets/logo.png"
              />
            </span>
            <p class="ml-3 font-medium text-white">
              {{ this.$t('table_appraiser_info') }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="-my-2 overflow-x-auto">
      <div class="py-2 align-middle inline-block min-w-full">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th v-for="th in headers" :key="th" :class="headerClasses">
                  {{ th }}
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <template v-for="person in persons">
                <tr
                  v-for="(dif, index) in person.specialities"
                  :key="person.id + index"
                  @click="goToElDetails(person)"
                  class="hover:bg-blue-100"
                >
                  <td :class="rowClasses">
                    {{ person.names }}
                  </td>
                  <td :class="rowClasses" class="lowercase">
                    {{ person.primary_voivodeship }}
                  </td>
                  <td :class="numberClasses">
                    {{ person.permit_number }}
                  </td>
                  <td :class="rowClasses">
                    {{ dif.discipline }}
                  </td>
                  <td :class="rowClasses">
                    {{ dif.speciality }}
                  </td>
                </tr>
              </template>
              <tr>
                <td colspan="8">
                  <AppraiserPagination></AppraiserPagination>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppraiserPagination from './AppraiserPagination'
import { mapGetters } from 'vuex'

export default {
  name: 'AppraiserTable',
  components: { AppraiserPagination },
  data: function() {
    return {
      headerClasses:
        'px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-900 uppercase tracking-wider',
      rowClasses:
        'px-6 py-4 whitespace-pre-wrap text-sm leading-5 text-gray-700',
      numberClasses:
        'px-6 py-4 whitespace-pre-wrap text-sm leading-5 text-gray-700 text-center',
      no_limits:
        'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-300 text-green-800',
      limited:
        'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-200 text-green-800'
    }
  },
  computed: {
    headers() {
      return JSON.parse(this.$t('appraiser_table_headers').replace(/'/g, '"'))
    },
    ...mapGetters({
      persons: 'appraiserCards/mapResults'
    })
  },
  methods: {
    goToElDetails(person) {
      this.$router.push({
        name: 'appraiser-detail',
        params: {
          id: person.id,
          person: person
        }
      })
    }
  }
}
</script>
