<template>
  <label
    :class="'block text-sm font-medium leading-5 text-gray-700 ' + labelClass"
  >
    {{ label }}
    <input
      v-bind="$attrs"
      v-on:input="$emit('input', $event.target.value)"
      class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm
           focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150
            ease-in-out sm:text-sm sm:leading-5"
    />
  </label>
</template>

<script>
export default {
  name: 'TextField',
  props: {
    label: {
      type: String,
      required: true
    },
    labelClass: {
      type: String,
      required: false
    }
  }
}
</script>
