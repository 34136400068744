<template>
  <div id="verify-document" class="container p-3 sm:px-6 lg:px-8 py-4">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'VerifyDocument'
}
</script>
