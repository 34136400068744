export const selected = {
  computed: {
    selectedNames: {
      get() {
        return this.$store.state.selectedSet.selectedNames
      },
      set(value) {
        this.$store.commit('selectedSet/SET_NAMES', value)
      }
    },
    selectedDecisionNum: {
      get() {
        return this.$store.state.selectedSet.selectedDecisionNum
      },
      set(value) {
        this.$store.commit('selectedSet/SET_DECISION', value)
      }
    },
    selectedAppraiserDecisionNum: {
      get() {
        return this.$store.state.selectedSet.selectedAppraiserDecisionNum
      },
      set(value) {
        this.$store.commit('selectedSet/SET_APPRAISER_DECISION', value)
      }
    },
    selectedRegion: {
      get() {
        return this.$store.state.selectedSet.selectedRegion
      },
      set(value) {
        this.$store.commit('selectedSet/SET_REGION', value)
      }
    },
    selectedCategory: {
      get() {
        return this.$store.state.selectedSet.selectedCategory
      },
      set(value) {
        this.$store.commit('selectedSet/SET_CATEGORY', value)
      }
    },
    selectedDiscipline: {
      get() {
        return this.$store.state.selectedSet.selectedDiscipline
      },
      set(value) {
        this.$store.commit('selectedSet/SET_DISCIPLINE', value)
      }
    },
    selectedRegulation: {
      get() {
        return this.$store.state.selectedSet.selectedRegulation
      },
      set(value) {
        this.$store.commit('selectedSet/SET_REGULATION', value)
      }
    },
    selectedAppraiserRegulationSpeciality: {
      get() {
        return this.$store.state.selectedSet
          .selectedAppraiserRegulationSpeciality
      },
      set(value) {
        this.$store.commit(
          'selectedSet/SET_APPRAISER_REGULATION_SPECIALITY',
          value
        )
      }
    },
    selectedExtent: {
      get() {
        return this.$store.state.selectedSet.selectedExtent
      },
      set(value) {
        this.$store.commit('selectedSet/SET_EXTENT', value)
      }
    }
  }
}
