<template>
  <div>
    <Header :msg="$t('header_message2')"></Header>
    <HeroSection>
      <template #img>
        <img
          class="h-56 w-full object-contain sm:h-72 md:h-96 lg:w-full lg:h-full"
          :alt="$t('hero_image')"
          src="../assets/images/f.png"
        />
      </template>
      <template #text>
        <div class="sm:text-center lg:text-left">
          <h2
            class="mb-4 lg:mb-10 text-xl tracking-tight leading-loose font-extrabold text-gray-900 sm:text-3xl sm:leading-tight"
          >
            {{ $t('header_message') }}
            <span class="text-indigo-600 ">(e-CRUB)</span>
            <span class="block">
              {{ $t('header_message-second-part') }}
            </span>
          </h2>
          <div
            class="flex justify-center lg:justify-start items-center w-full md:w-2/3"
          >
            <a
              href="#search"
              @click="showDefaultSearch"
              class="btn btn-primary py-5 px-8 block"
              v-smooth-scroll
            >
              Szukaj w rejestrze osób posiadających uprawnienia budowlane
            </a>
            <div class="pt-8 pb-10"></div>
          </div>
          <!-- <div
            class="flex justify-center lg:justify-start items-center w-full md:w-2/3 mt-2"
          >
            <a
              href="#search"
              @click="showAppraiserSearch"
              v-smooth-scroll
              class="btn btn-primary py-5 px-8 block"
            >
              Szukaj w rejestrze rzeczoznawców budowlanych
            </a>
            <div class="pt-8 pb-10"></div>
          </div>-->
        </div>
      </template>
    </HeroSection>
    <div>
      <div class="container p-3 mt-6 sm:px-6 lg:px-8">
        <span
          v-show="displayDefaultSearch()"
          class="mb-4 mt-4 lg:mb-10 text-xl tracking-tight leading-loose font-semibold text-gray-900 sm:text-3xl sm:leading-tight"
        >
          Wyszukiwanie w rejestrze osób posiadających uprawnienia budowlane
        </span>
        <span
          v-show="displayAppraiserSearch()"
          class="mb-4 lg:mb-10 text-xl tracking-tight leading-loose font-semibold text-gray-900 sm:text-3xl sm:leading-tight"
        >
          Wyszukiwanie w rejestrze rzeczoznawców budowlanych
        </span>
      </div>

      <transition name="slide-fade">
        <SearchContainer v-show="!this.$store.state.base.hidden">
          <DefaultSearch v-show="this.searchMode === 'default'"></DefaultSearch>
          <AppraiserSearch
            v-show="this.searchMode === 'appraiser'"
          ></AppraiserSearch>
        </SearchContainer>
      </transition>

      <transition name="slide-fade">
        <VerifyDocumentContainer v-show="this.searchMode === 'verify-document'">
          <VerifyDocument></VerifyDocument>
        </VerifyDocumentContainer>
      </transition>

      <Table v-show="displayTable()"></Table>
      <AppraiserTable v-show="displayAppraiserTable()"></AppraiserTable>
      <div class="bg-indigo-600">
        <div class="container">
          <div class="justify-center items-center">
            <p
              v-html="this.$t('faq_link')"
              class="mx-8 font-medium text-gray py-8 text-md text-left"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Table from '@/components/Table'
import AppraiserTable from '@/components/AppraiserTable.vue'
import HeroSection from '@/components/HeroSection'
import DefaultSearch from '@/components/DefaultSearch'
import AppraiserSearch from '@/components/AppraiserSearch'
import SearchContainer from '@/components/SearchContainer'
import VerifyDocumentContainer from '@/components/VerifyDocumentContainer'
import VerifyDocument from '@/components/VerifyDocument'
import { mapActions } from 'vuex'

export default {
  name: 'Home',
  components: {
    AppraiserSearch,
    DefaultSearch,
    SearchContainer,
    HeroSection,
    Table,
    AppraiserTable,
    Header,
    VerifyDocumentContainer,
    VerifyDocument
  },
  data() {
    return {
      searchMode: 'default' // default | appraiser | verify-document
    }
  },
  methods: {
    ...mapActions({
      getDisciplines: 'disciplines/getDisciplines',
      getRegulations: 'regulations/getRegulations',
      getCategories: 'specialityCategories/getCategories',
      getVoivodeships: 'voivodeships/getVoivodeships'
    }),

    showDefaultSearch() {
      this.prepareSearching()
      this.searchMode = 'default'
    },
    showAppraiserSearch() {
      this.prepareSearching()
      this.searchMode = 'appraiser'
    },
    showVerifyDocument() {
      this.searchMode = 'verify-document'
      if (!this.$store.state.base.hidden) {
        this.$store.commit('base/SET_HIDDEN')
      }
    },
    prepareSearching() {
      if (this.$store.state.base.hidden) {
        this.$store.commit('base/SET_HIDDEN')
      }
      this.$store.commit('professionalCards/SET_PROFESSIONAL_CARDS', [])
      this.$store.commit('appraiserCards/SET_APPRAISER_CARDS', [])
      this.$store.commit('selectedSet/SET_NAMES', '')
      this.$store.commit('selectedSet/SET_DECISION', '')
      this.$store.commit('selectedSet/SET_REGION', null)
      this.$store.commit('selectedSet/SET_CATEGORY', null)
      this.$store.commit('selectedSet/SET_DISCIPLINE', null)
      this.$store.commit('selectedSet/SET_REGULATION', null)
      this.$store.commit('selectedSet/SET_EXTENT', null)
      this.$store.commit(
        'selectedSet/SET_APPRAISER_REGULATION_SPECIALITY',
        null
      )
      this.$store.commit('selectedSet/SET_APPRAISER_DECISION', '')
    },
    displayTable() {
      return (
        this.$store.state.professionalCards.totalRecords > 0 &&
        this.$store.state.professionalCards.professionalCards.length > 0
      )
    },
    displayAppraiserTable() {
      return (
        this.$store.state.appraiserCards.totalRecords > 0 &&
        this.$store.state.appraiserCards.appraiserCards.length > 0
      )
    },
    displayDefaultSearch() {
      return !this.$store.state.base.hidden && this.searchMode === 'default'
    },
    displayAppraiserSearch() {
      return !this.$store.state.base.hidden && this.searchMode === 'appraiser'
    }
  },
  mounted() {
    this.getDisciplines()
    this.getVoivodeships()
    this.getCategories()
    this.getRegulations()
  }
}
</script>
