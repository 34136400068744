<template>
  <div class="flex w-full flex-col">
    <span
      class="mb-4 lg:mb-10 text-xl tracking-tight leading-loose font-semibold text-gray-900 sm:text-3xl sm:leading-tight"
    >
      Wybierz metodę weryfikacji dokumentu
    </span>
    <div class="flex justify-center lg:justify-start items-center w-full mt-4">
      <a
        href="#verify"
        @click="showQrForm"
        v-smooth-scroll
        class="btn btn-primary py-5 px-8 w-full block"
      >
        Sprawdź za pomocą kodu QR
      </a>
    </div>
    <div class="flex justify-center lg:justify-start items-center w-full  mt-2">
      <a
        href="#verify"
        @click="showIdForm"
        v-smooth-scroll
        class="btn btn-primary py-5 px-8 w-full block"
      >
        Sprawdź za pomocą sygnatury
      </a>
    </div>

    <div v-show="verifyMode === 'id'">
      <div class="md:w-1/2 w-full mt-4">
        <div class="col-span-6">
          <TextField
            v-model="documentId"
            :labelClass="correctLabelClass"
            label="Wpisz sygnaturę dokumentu"
          ></TextField>
        </div>
      </div>
      <transition name="slide-fade">
        <div class="w-full flex items-end place-content-end mt-4">
          <button class="btn-search inline-block">
            Sprawdź
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import TextField from './TextField'

export default {
  components: { TextField },

  name: 'VerifyDocument',

  data() {
    return {
      verifyMode: null, // 'qr' | 'id'
      documentId: ''
    }
  },
  computed: {
    correctLabelClass() {
      return this.testNames || !this.selectedNames ? '' : 'text-red-700'
    }
  },
  methods: {
    showQrForm() {
      this.verifyMode = 'qr'
    },
    showIdForm() {
      this.verifyMode = 'id'
    }
  },
  mounted() {}
}
</script>
