<template>
  <div class="shadow overflow-hidden sm:rounded-md">
    <div class="px-4 py-5 bg-white sm:p-6">
      <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6">
          <TextField
            :labelClass="correctLabelClass"
            :label="correctLabel"
            v-model="selectedNames"
          ></TextField>
        </div>
        <div class="col-span-6 lg:col-span-3">
          <SelectField
            :label="this.$t('region')"
            v-model="selectedRegion"
            :options="voivodeships"
            :optionValue="'id'"
            selectClass="lowercase"
            :optionName="'name'"
          >
            <option value=""></option>
          </SelectField>
        </div>
        <div class="col-span-6 lg:col-span-3">
          <TextField
            :label="'numer decyzji o nadaniu tytułu rzeczoznawcy'"
            v-model="selectedAppraiserDecisionNum"
          ></TextField>
        </div>
        <div class="col-span-6">
          <SelectField
            :label="'dziedzina (rodzaj uprawnień rzeczoznawcy)'"
            v-model="selectedDiscipline"
            :options="disciplines"
            :optionValue="'ids'"
            :optionName="'name'"
          >
            <option value=""></option>
          </SelectField>
        </div>

        <div class="col-span-6">
          <SelectField
            :label="'specjalność uprawnień budowlanych rzeczoznawcy'"
            v-model="selectedRegulationIndex"
            :options="regulations"
            :optionValue="'index'"
            :optionName="'name'"
          >
            <option value=""></option>
          </SelectField>
        </div>

        <transition name="slide-fade">
          <div v-if="selectedRegulationIndex" class="col-span-6">
            <label
              v-for="item in selectedSpecialities"
              :key="item.id"
              class="flex items-center ml-2 mt-4"
            >
              <input
                class="form-radio"
                type="radio"
                :value="item.id"
                v-model="selectedAppraiserRegulationSpeciality"
              />

              <span class="ml-2">{{ item.name }}</span>
            </label>
          </div>
        </transition>
      </div>
    </div>
    <div class="px-4 py-3 bg-white text-right sm:px-6">
      <transition name="slide-fade">
        <button
          v-if="appraiserQueryNotEmpty"
          @click="startSearching"
          href="#table"
          class="btn-search inline-block"
          v-smooth-scroll
        >
          {{ this.$t('search') }}
        </button>
      </transition>
    </div>
  </div>
</template>

<script>
import TextField from './TextField'
import SelectField from './SelectField'
import { selected } from '../mixins/selected.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AppraiserSearch',
  mixins: [selected],
  data() {
    return {
      selectedRegulationIndex: null,
      selectedSpecialities: []
    }
  },
  components: { SelectField, TextField },
  computed: {
    ...mapGetters({
      disciplines: 'disciplines/mapDisciplines',
      regulations: 'regulations/mapRegulations',
      voivodeships: 'voivodeships/mapVoivodeships',
      categories: 'specialityCategories/mapCategories',
      categoryName: 'specialityCategories/categoryName',
      extentName: 'specialityCategories/extentName',
      findSpecialities: 'specialityCategories/findSpecialities',
      queryParams: 'selectedSet/getAppraiserQueryParams',
      mapNames: 'selectedSet/mapNames',
      testNames: 'selectedSet/testNames',
      appraiserQueryNotEmpty: 'selectedSet/appraiserQueryNotEmpty',
      queryIsEmpty: 'selectedSet/queryIsEmpty',
      extents: 'specialityCategories/mapExtents'
    }),
    correctLabel() {
      return this.testNames || !this.selectedNames
        ? this.$t('names')
        : this.$t('set_correct_names')
    },
    correctLabelClass() {
      return this.testNames || !this.selectedNames ? '' : 'text-red-700'
    }
  },
  methods: {
    ...mapActions({
      search: 'appraiserCards/getAppraiserCards'
    }),
    startSearching() {
      this.selectedAppraiserRegulationSpeciality = null
      this.selectedRegulationIndex = null
      this.$store.commit('base/SET_HIDDEN')
      this.search(this.queryParams)
    }
  },
  watch: {
    selectedRegulationIndex: {
      async handler(index) {
        this.selectedAppraiserRegulationSpeciality = null
        const regulation = this.regulations.find(regulation => {
          return Number(regulation.index) === Number(index)
        })
        this.selectedSpecialities = regulation.specialities
      }
    }
  }
}
</script>
<style>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(50px);
  opacity: 0;
}
</style>
