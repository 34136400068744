<template>
  <div id="search" class="container p-3 sm:px-6 lg:px-8 py-4">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            {{ this.$t('find_someone') }}
          </h3>
          <p class="mt-1 text-sm leading-5 text-gray-900">
            {{ this.$t('find_someone_info') }}
          </p>
          <p
            v-html="this.$t('about_problems')"
            class="mt-2 text-sm leading-5 text-gray-900"
          ></p>
        </div>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchContainer'
}
</script>
